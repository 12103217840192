import React, { Component } from "react"
import { Link } from "gatsby"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap"
import nodeUtils from "../../../utils/node.utils"

export default class CarouselIndex extends Component {
  constructor(props) {
    super(props)
    this.items = this.props.items
    this.state = {
      activeIndex: 0,
    }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
  }

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === this.items.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? this.items.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const {
      state: { activeIndex },
      props: { contentMap },
    } = this

    const buttonShop = nodeUtils.GetSingleContentLink(contentMap.button_shop)

    const slides = this.items.map(item => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
          role={item.altText}
        >
          <div className="item home-full-item carousel-item-container">
            <img
              src={item.src}
              alt={item.altText}
              title={item.altText}
              className="bg-image carousel-data"
            />

            <div className="container-fluid h-100 py-5">
              <div className="row align-items-end h-100">
                <div className="d-none d-lg-block col-12 mx-auto text-center text-uppercase text-gray-700 text-xl carousel-shade">
                  <div className="mt-2 mb-2">{item.altText}</div>
                </div>
                <div className="d-block d-lg-none col-12 mx-auto text-center text-uppercase text-gray-700 carousel-shade">
                  <div className="mt-2 mb-2">{item.altText}</div>
                </div>
                <div className="col-12 mx-auto text-white text-center"></div>
                <div className="col-12 mx-auto text-white text-center"></div>
                <div className="col-12 mx-auto text-white text-center"></div>
                <div className="col-12 mx-auto text-white text-center">
                  <p className="lead mb-4 text-uppercase text-gray-700 font-weight-lighter"></p>
                  <p>
                    <Link
                      to={buttonShop.link}
                      title={buttonShop.value}
                      className="btn btn-dark"
                      role="button"
                    >
                      {buttonShop.value}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </CarouselItem>
      )
    })

    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
      >
        <CarouselIndicators
          items={this.items}
          activeIndex={activeIndex}
          onClickHandler={this.goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={this.previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={this.next}
        />
      </Carousel>
    )
  }
}
