import React, { Component } from "react"
import Utils from "../../../utils/node.utils"
import Category from "./Category"

const homeCategories = {
  necklaces: "jewelry",
  earrings: "jewelry",
  rings: "jewelry",
  bracelets: "jewelry",
  "personalized accessories": "accessories",
  bags: "accessories",
}

export default class Categories extends Component {
  getCategories = () => {
    let retval = []
    const { commonContentMap, contentMap, categories } = this.props

    const categoryMap = {}

    for (let i in categories) {
      const c = categories[i]

      categoryMap[c.key.toLowerCase()] = c
    }

    for (let category in homeCategories) {
      const categoryContent = contentMap[category]
      const categoryContentLink = Utils.GetSingleContentLink(categoryContent)

      const name = Utils.GetContentKeyValue(
        categoryMap[category].content,
        "name"
      )
      const thumbnail = Utils.GetContentKeyValue(
        categoryMap[category].content,
        "thumbnails"
      )

      retval.push(
        <Category
          key={category}
          name={name}
          img={thumbnail}
          alt={name}
          title={name}
          commonContentMap={commonContentMap}
          link={categoryContentLink.link}
        />
      )
    }
    return retval
  }

  render() {
    const { contentMap } = this.props

    return (
      <div className="container mt-2 mt-lg-6 ">
        <div className="row">
          <div className="col-xl-8 mx-auto text-center mb-3">
            <h2 className="text-uppercase text-size-vw">
              {contentMap.h_categories.value}
            </h2>
          </div>
        </div>
        <div className="row">{this.getCategories()}</div>
      </div>
    )
  }
}
