import React, { Component } from "react"
import { Link } from "gatsby"

export default class Category extends Component {
  render() {
    const { name, img, alt, link, commonContentMap } = this.props

    const viewButtonContent = commonContentMap.button_view.value
    const title = viewButtonContent + " " + name

    return (
      <div className="col-lg-4">
        <div className="product">
          <div className="category-image text-center">
            <img src={img} alt={alt} title={alt} className="img-fluid" />
            <div className="product-hover-overlay">
              <Link
                to={link}
                title={title}
                className="product-hover-overlay-link"
                aria-label={viewButtonContent}
              ></Link>

              <div className="product-hover-overlay-buttons">
                <Link to={link} title={title} className="btn btn-dark btn-buy">
                  <i className="fa-search fa" />
                  <span className="btn-buy-label ml-2">
                    {viewButtonContent}
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="py-2 text-center">
            <h3 className="h6 text-uppercase mb-1">{name}</h3>
          </div>
        </div>
      </div>
    )
  }
}

Category.defaultProps = {
  name: "",
  img: { uri: "" },
  alt: "",
  link: "",
}
