import React, { Component } from "react"
import Layout from "../Layout/Layout/Layout"
import SEO from "../SEO/SEO"
import Utils from "../../utils/node.utils"
import Bestsellers from "../Home/Bestsellers/Bestsellers"
import Categories from "../Home/Categories/Categories"
import CarouselIndex from "../Shared/Carousel/CarouselIndex"

export default class IndexPage extends Component {
  componentDidMount() {
    this.setState({
      subCategories: Utils.ParseCategoryFilters(
        this.props.location.search,
        this.props.subCategories
      ),
    })
  }

  render() {
    const {
      title,
      description,
      language,
      location,
      contentMap,
      commonContentMap,
      keywords,
      products,
      carouselItems,
      categories,
    } = this.props

    return (
      <Layout
        origin={location.origin}
        language={language}
        originPage=""
        commonContentMap={commonContentMap}
      >
        <SEO
          title={title}
          language={language}
          description={description}
          keywords={keywords}
        />
        <CarouselIndex items={carouselItems} contentMap={contentMap} />
        <Bestsellers
          contentMap={contentMap}
          language={language}
          commonContentMap={commonContentMap}
          products={products}
        />
        <Categories
          contentMap={contentMap}
          commonContentMap={commonContentMap}
          categories={categories}
        />
      </Layout>
    )
  }
}
