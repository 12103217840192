import React, { Component } from "react"
import Utils from "../../../utils/node.utils"
import { Link } from "gatsby"

export default class Bestseller extends Component {
  render() {
    const { name, slug, img, alt, language, commonContentMap } = this.props
    const productLink = Utils.GetLanguageLink(language, "/shop/" + slug)

    const viewButtonContent = commonContentMap.button_view.value
    const title = viewButtonContent + " " + name

    return (
      <div className="col-lg-4 mr-4">
        <div className="product bestseller-size">
          <div className="product-image">
            <img src={img} alt={alt} title={alt} className="img-fluid" />
            <div className="product-hover-overlay py-2">
              <Link
                to={productLink}
                title={title}
                className="product-hover-overlay-link"
                aria-label={viewButtonContent}
              ></Link>
              <div className="product-hover-overlay-buttons">
                <Link
                  to={productLink}
                  title={title}
                  className="btn btn-dark btn-buy"
                >
                  <i className="fa-search fa" />
                  <span className="btn-buy-label ml-2">
                    {viewButtonContent}
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="text-uppercase text-center mt-1 mb-1">
            <Link to={productLink} title={title} className="text-dark">
              {name}
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

Bestseller.defaultProps = {
  id: "",
  name: "",
  categories: [],
  price: { value: 0, currency: "EUR" },
  img: { uri: "" },
  alt: "",
}
