import React, { Component } from "react"
import Bestseller from "./Bestseller"
import Utils from "../../../utils/node.utils"

export default class Bestsellers extends Component {
  reduceBestsellers = () => {
    const { products } = this.props

    return products.slice(0, 10)
  }

  getBestSellers = () => {
    const { language, commonContentMap } = this.props
    return this.reduceBestsellers().map(
      ({ node: { item: product } }, index) => {
        const name = Utils.GetContentKeyValue(product.content, "name")
        const thumbnail = Utils.GetContentKeyValue(
          product.content,
          "thumbnails"
        )

        return (
          <Bestseller
            key={index}
            id={product.id}
            slug={product.slug}
            name={name}
            img={thumbnail}
            alt={name}
            language={language}
            commonContentMap={commonContentMap}
          />
        )
      }
    )
  }

  render() {
    const { contentMap } = this.props
    return (
      <div>
        <section className="mt-2 mt-lg-6 py-1">
          <div className="container">
            <div className="">
              <div className="col-xl-8 mx-auto text-center mb-3">
                <h2 className="text-uppercase text-size-vw">
                  {contentMap.h_bestsellers.value}
                </h2>
              </div>
            </div>

            <div className="row-scroll">{this.getBestSellers()}</div>
          </div>
        </section>
      </div>
    )
  }
}
